@import "src/styles";

.news-item {
    display: flex;
    height: 100vh;
    background: linear-gradient(transparent 33%, var(--c-black));

    &__image {
        position: absolute;
        background-color: var(--c-white);
        height: 100vh;
        width: 100%;
        object-fit: cover;
        z-index: -10;
    }

    &__content {
        display: grid;
        grid-template-columns: auto 7.5rem;
        gap: 3rem;
        padding: 4rem;
        width: 100%;

        @include for-size(desktop-up) {
            gap: 5rem;
            padding: 8rem 10rem 8rem 6rem;
        }
        
        @include for-size(big-desktop-up) {
            grid-template-columns: auto 12rem;
        }

        @include for-size(biggest-desktop-up) {
            grid-template-columns: auto 18rem;
            gap: 7rem;
            padding: 18rem 22rem 18rem 12rem;
        }
    }
    
    &__content-left,
    &__content-right {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    &__author-wrapper {
        display: flex;
        align-items: center;
    }

    &__author-image {
        @include circle(4rem);

        object-fit: cover;

        @include for-size(desktop-up) {
            @include circle(6rem);
        }

        @include for-size(biggest-desktop-up) {
            @include circle(8rem);
        }
    }

    &__author {
        margin-left: 1rem;
        font-weight: var(--fw-regular);
        font-size: 1.5rem;

        @include for-size(desktop-up) {
            margin-left: 2rem;
            font-size: 2rem;
        }

        @include for-size(biggest-desktop-up) {
            margin-left: 2.5rem;
            font-size: 2.5rem;
        }
    }

    &__title-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
    }

    &__title {
        align-self: flex-end;
        margin-bottom: -.75rem;
        font-size: 1.75rem;

        @include for-size(desktop-up) {
            font-size: 5rem;
        }

        @include for-size(biggest-desktop-up) {
            font-size: 8rem;
        }
    }

    &__qr-code {
        @include square(7.5rem);

        padding: .75rem;

        @include for-size(big-desktop-up) {
            @include square(12rem);
            padding: 1rem;
        }

        @include for-size(biggest-desktop-up) {
            @include square(18rem);

            padding: 1.5rem;
        }
    }

    &__qr-code-title {
        text-align: center;
        font-size: 1rem;
        color: var(--c-white);

        @include for-size(biggest-desktop-up) {
            margin-top: .5rem;
            font-size: 1.75rem;
        }
    }
    
    &__highlight {
        position: relative;
        max-width: 85%;
        padding-left: 1.5rem;
        font-size: 1rem;
        line-height: 1.25;
        font-weight: 325;
        color: var(--c-white);
        
        &:before {
            position: absolute;
            top: calc(0.25 * (1.25 * 0.75rem));
            left: 0;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            content: '';
            background-color: var(--c-red);
        }
        
        &:not(:last-of-type) {
            margin-bottom: 2rem;
        }

        @include for-size(desktop-up) {
            padding-left: 3rem;
            line-height: 1.25;
            font-size: 2rem;
            
            &:before {
                top: calc(0.25 * (1.25 * 1.65rem));
                width: 0.75rem;
                height: 0.75rem;
            }
        }

        @include for-size(biggest-desktop-up) {
            padding-left: 3rem;
            line-height: 1.25;
            font-size: 4rem;
            
            &:before {
                top: calc(0.4 * (1.25 * 2.75rem));
            }
        }
    }
    
    &__highlight-wrapper {
        margin-top: 4rem;
        max-height: 50vh;
        overflow: hidden;

        @include for-size(desktop-up) {
            max-height: 40vh;
        }
        
        @include for-size(biggest-desktop-up) {
            margin-top: 10rem;
        }
    }
}