@import "src/styles";

.location-map-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    &__image {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    &__north-america {
        position: absolute;
        left: 22%;
        animation-delay: .5s;
    }

    &__western-europe {
        position: absolute;
        top: 42%;
        left: 47%;
        animation-delay: 1.25s;
    }

    &__eastern-europe {
        position: absolute;
        top: 49%;
        left: 53%;
        animation-delay: 2s;
    }

    &__asia-pacific {
        position: absolute;
        top: 50%;
        left: 76%;
        animation-delay: 2.75s;
    }

    &__total-number-wrapper {
        position: absolute;
        bottom: 5rem;
        left: 2rem;
        font-weight: 300;
        animation-delay: .5s;
    }

    &__total-number-title {
        text-align: left;
        font-size: 1.875rem;
        font-family: Gotham, sans-serif;
    }

    &__total-number {
        display: flex;
        align-items: first baseline;
    }
}
