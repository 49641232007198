@import "src/styles";

.container-location {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 0;
    height: 0;
    font-weight: var(--fw-lighter);
    animation: animate-in 1s ease-in-out 0s forwards;
    opacity: 0;

    @include for-size(bigger-desktop-up) {

        &__counter {
            font-size: 5.5rem;
        }

        &__title {
            font-size: 1rem;
        }
    }

    &__counter {
          text-align: left;
          white-space: nowrap;
          clip-path: polygon(50% 10%, 78% 8%, 100% 5%, 100% 94%, 75% 92%, 50% 91%, 24% 92%, 0 95%, 0 5%, 21% 9%);
          font-size: 3.125rem;
          transition: .5s;

          span {
              font-family: var(--f-primary);
          }
    }

    &__title {
        margin: 0;
        white-space: nowrap;
        font-weight: 400;
        font-size: 1rem;
        color: var(--c-red);
        transition: .5s;
    }
}