@keyframes spin {
    to { rotate: 1turn; }
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes skeleton-load {
    from { translate: -100% 0; }
    to { translate: 100% 0; }
}

@keyframes bg-slide-in {
    80% {
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes block-slide-in {
    to {
        width: 100%;
    }
}

@keyframes block-slide-in-out {
    to {
        transform: translateX(100%);
    }
}

@keyframes vertical-news-item-fade-in {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes vertical-news-slide-left {
    // Observer is listening for position change
    0%  {
        position: relative;
    }

    100% {
        transform: translateY(var(--left-articles-y-transform));
    }
}

@keyframes vertical-news-slide-right {
    to {
        transform: translateY(var(--right-articles-y-transform));
    }
}

@keyframes animate-in {
    from {
      transform: translateY(-10px);
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
}
